// TODO: remove lodash!
import lodash_isEqual from 'lodash.isequal'
import lodash_remove from 'lodash.remove'
import lodash_curry from 'lodash.curry'
import lodash_clone from 'lodash.clone'
import lodash_cloneDeep from 'lodash.clonedeep'
import lodash_capitalize from 'lodash.capitalize'
import lodash_uniq from 'lodash.uniq'
import lodash_merge from 'lodash.merge'
import lodash_sortBy from 'lodash.sortby'
import lodash_flatten from 'lodash.flatten'
import lodash_flattenDeep from 'lodash.flattendeep'
import lodash_mapValues from 'lodash.mapvalues'
import lodash_groupBy from 'lodash.groupby'
import lodash_shuffle from 'lodash.shuffle'

// Some helpers from d3.
export { deviation } from 'd3-array'

export function indexBy(arr, keyOrFn) {
  if (typeof keyOrFn !== 'function') {
    const key = keyOrFn
    keyOrFn = value => value[key]
  }
  return arr.reduce(function(obj, value) {
    obj[keyOrFn(value)] = value
    return obj
  }, {})
}

// Inspired by: https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_keyBy
export function reduceIndexBy(key) {
  return (r, x) => ({ ...r, [key ? x[key] : x]: x })
}

export function reduce(objOrArray, fn, initial) {
  if (!objOrArray) {
    return initial
  }
  if (Array.isArray(objOrArray)) {
    return objOrArray.reduce(fn, initial)
  } else {
    return Object.entries(objOrArray).reduce((n, [k, v]) => fn(n, v, k), initial)
  }
}

export function min(objOrArray) {
  if (Array.isArray(objOrArray)) {
    return Math.min(...objOrArray)
  }
  return reduce(objOrArray, Math.min, Number.MAX_VALUE)
}

export function max(objOrArray) {
  if (Array.isArray(objOrArray)) {
    return Math.max(...objOrArray)
  }
  return reduce(objOrArray, Math.max, Number.MIN_VALUE)
}

export function sum(objOrArray) {
  return reduce(objOrArray, (s, v) => s + v, 0)
}

export function map(objOrArray, fn) {
  if (!objOrArray) {
    return
  }
  if (Array.isArray(objOrArray)) {
    return objOrArray.map(fn)
  } else {
    const result = {}
    for (const [key, value] of Object.entries(objOrArray)) {
      result[key] = fn(value, key)
    }
    return result
  }
}

export function mean(objOrArray, accessorFn) {
  if (!objOrArray) {
    return NaN
  }
  let sum
  let count = 0
  if (!accessorFn) {
    accessorFn = function(d) {
      return d
    }
  }
  if (Array.isArray(objOrArray)) {
    count = objOrArray.length
    sum = objOrArray.reduce((s, v) => s + accessorFn(v), 0)
  } else {
    count = Object.keys(objOrArray).length
    sum = Object.values(objOrArray).reduce((s, v) => s + accessorFn(v), 0)
  }
  return count !== 0 ? sum / count : 0
}

export function isEqual(objOrArray1, objOrArray2) {
  return lodash_isEqual(objOrArray1, objOrArray2)
}

export function forEach(objOrArray, fn) {
  if (!objOrArray) {
    return
  }
  if (Array.isArray(objOrArray)) {
    objOrArray.forEach(fn)
  } else {
    Object.keys(objOrArray).forEach(k => fn(objOrArray[k], k))
  }
}

// Adapted from:  https://gist.github.com/bhavyaw/25b115603630ebf2271d
export function extend(out, ...args) {
  out = out || {}
  args.forEach((arg) => {
    for (const [key, value] in Object.entries(arg)) {
      out[key] = value
    }
  })
  return out
}

export function filter(objOrArray, fn) {
  if (Array.isArray(objOrArray)) {
    return objOrArray.filter(fn)
  } else {
    const result = {}
    Object.keys(objOrArray).forEach((k) => {
      const v = objOrArray[k]
      if (fn(v, k)) {
        result[k] = v
      }
    })
    return result
  }
}

export function values(objOrArray) {
  if (Array.isArray(objOrArray)) {
    return objOrArray
  } else {
    return Object.values(objOrArray)
  }
}

export function curry(fn) {
  return lodash_curry(fn)
}

export function remove(objOrArray, fn) {
  return lodash_remove(objOrArray, fn)
}

export function copy(objOrArray) {
  if (typeof objOrArray === 'undefined' || objOrArray === null) {
    return objOrArray
  }
  return lodash_clone(objOrArray)
}

export function copyDeep(objOrArray) {
  if (typeof objOrArray === 'undefined' || objOrArray === null) {
    return objOrArray
  }
  return lodash_cloneDeep(objOrArray)
}

export function capitalize(s) {
  return lodash_capitalize(s)
}

export function uniq(objOrArray) {
  return lodash_uniq(objOrArray)
}

export function merge(object, source, srcIndex) {
  return lodash_merge(object, source, srcIndex)
}

export function sortBy(collection, iteratees) {
  return lodash_sortBy(collection, iteratees)
}

export function clone(thing) {
  return JSON.parse(JSON.stringify(thing))
}

export const sortByName = (d1, d2) => d1?.name?.localeCompare(d2?.name)

const pathRE = /^(.*\/)([^/]*)$/
export function parsePath(path) {
  const parsed = pathRE.exec(path)
  return { path: parsed[1], name: parsed[2] }
}

export function flatten(arr) {
  return lodash_flatten(arr)
}

export function flattenDeep(arr) {
  return lodash_flattenDeep(arr)
}

export function timeout(ms) {
  return new Promise((resolve, reject) => {
    window.setTimeout(resolve, ms)
  })
}
export function mapValues(obj, fn) {
  return lodash_mapValues(obj, fn)
}

export function groupBy(obj, fn) {
  return lodash_groupBy(obj, fn)
}

export function shuffle(obj, fn) {
  return lodash_shuffle(obj, fn)
}
