/* eslint-disable */

import { Logger, functions } from '@/util';

const log = Logger.get('yb.time-series');

export default function TimeSeries(begin, end, label, options) {
  begin = +begin;
  end = +end;
  label = label || '(none)';
  options = options || {};
  var data = [];
  var lastTime = begin,
    lastValue;
  var PAD_SIZE = options.padSize || 10000;
  var pad = options.pad;
  var id = options.id || null;
  this.add = function(count, b, e) {
    // Pad segments if (begin time - last time) > PAD_SIZE
    if (b - lastTime > PAD_SIZE) {
      var iter = parseInt((b - lastTime) / PAD_SIZE);
      var inc = lastTime;
      var padValue = pad;
      if (typeof padValue === 'undefined') {
        padValue = lastValue;
        if (typeof padValue === 'undefined') {
          padValue = count;
        }
      }
      for (var i = 0; i < iter; ++i, inc += PAD_SIZE) {
        data.push({
          time: inc,
          count: padValue,
        });
      }
      data.push({
        time: b - 1,
        count: padValue,
      });
    }
    if (e) {
      while (b < e) {
        data.push({
          time: b,
          count: count,
        });
        b += PAD_SIZE;
      }
      data.push({
        time: e,
        count: count,
      });
      lastTime = e;
      lastValue = count;
    } else {
      data.push({
        time: b,
        count: count,
      });
      lastTime = b;
      lastValue = count;
    }
  };
  this.series = function(averaging) {
    // Add ending sample.
    if (data.length <= 1) {
      data = [];
    } else if (end - lastTime > PAD_SIZE) {
      this.add(0, end, end);
    }

    // Resample, based on naive window averaging.
    if (averaging && data.length > 2) {
      var index = 0,
        next = 0;
      var resampled = [];
      for (; next < data.length; ++next) {
        // Drop b/e to pad interval.
        data[next].time -= data[next].time % averaging;

        // Cross the interval?
        if (data[next].time - data[index].time > averaging) {
          resampled.push({
            time: data[index].time,
            count: functions.mean(data.slice(index, next), function(d) {
              return d.count;
            }),
          });
          index = next;
        }
      }
      if (index < data.length) {
        resampled.push({
          time: data[index].time,
          count: functions.mean(data.slice(index, next), function(d) {
            return d.count;
          }),
        });
      }
      data = resampled;
    }

    return {
      id: id,
      label: label,
      data: data,
    };
  };
  this.print = function() {
    function f(d) {
      return {
        time: d.time + ': ' + new Date(d.time).toString(),
        count: d.count,
      };
    }
    log.info(label + ':' + JSON.stringify(data.map(f), null, 1));
  };
  this.array = function() {
    if (arguments.length) {
      data = arguments[0];
    } else {
      return data;
    }
  };
  if (typeof pad === 'number') {
    this.add(pad, begin, begin);
  }
}
