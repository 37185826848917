export function removeChildren(el) {
  let child = el.lastElementChild
  while (child) {
    el.removeChild(child)
    child = el.lastElementChild
  }
}

// From c3js:
export function getClipPath(id) {
  const isIE9 = window.navigator.appVersion.toLowerCase().includes('msie 9.')
  return 'url(' + (isIE9 ? '' : document.URL.split('#')[0]) + '#' + id + ')'
}

export function getBoundingClientRect(e) {
  if (e && e.is) {
    e = e[[0]]
  }
  return (e && e.getBoundingClientRect()) || { width: 0, height: 0 }
}

export function getClientWidth(e) {
  return getBoundingClientRect(e).width
}

export function getClientHeight(e) {
  return getBoundingClientRect(e).height
}

export function isScrolledIntoView(e) {
  const rect = getBoundingClientRect(e)
  const elemTop = rect.top
  const elemBottom = rect.bottom

  return elemTop >= 0 && elemBottom <= window.innerHeight
}
