import sprintf from 'sprintf'

const levels = {
  trace: 0,
  debug: 1,
  info: 2,
  warn: 4,
  error: 8,
  off: 16
}

// Global store ref.
let store

function noop() {}

class LoggerInstance {
  constructor(name, level) {
    this.name = name
    this.prefix = sprintf('%-20s', `[${name}]`)
    this.setLevel(level)
    this.log = console.log.bind(window.console, this.prefix)
  }

  setLevel(level) {
    if (!level) {
      level = { value: levels.debug }
    }
    const target = typeof level === 'object' ? level.value : levels[level.toLowerCase()]
    for (const key in levels) {
      const value = levels[key]
      if (value >= target) {
        this[key] = (console[key] || console.log).bind(window.console, sprintf('%s | %-8s | ', this.prefix, key.toUpperCase()))
      } else {
        this[key] = noop
      }
    }
    if (console.table) {
      this.table = console.table.bind(console)
    }
    this.raw = console.log.bind(console)
    this.level = level.name || level
  }
}

class LoggerService {
  constructor() {
    this.loggers = {}
    Object.keys(levels).forEach((level) => {
      const name = level.toUpperCase()
      this[name] = { value: levels[level], name }
    })
    this.level = this.INFO
    this.globalLogger = this.get('yb.logger')
    this.globalLogger.debug('Logger constructed')
  }

  get(name) {
    return this.loggers[name] || (this.loggers[name] = new LoggerInstance(name, this.level))
  }

  getLogger(name) {
    return this.get(name)
  }

  setLevel(level, save) {
    this.level = typeof level === 'object' ? level : this[level.toUpperCase()]
    for (const logger in this.loggers) {
      this.loggers[logger].setLevel(level)
    }
    if (save) {
      this.save()
    }
  }

  save() {
    const loggers = Object.values(this.getLoggers()).map((logger) => {
      return {
        level: logger.level,
        logger: logger.name
      }
    })
    const settings = { level: this.level, loggers }
    window.localStorage.setItem('yb.log', JSON.stringify(settings))
  }

  getLevel() {
    return this.level
  }

  static setHandler() {} // noop

  getLoggers() {
    return this.loggers
  }
}

const singleton = new LoggerService()
export default singleton

try {
  // Get the settings.
  const settings = JSON.parse(window.localStorage.getItem('yb.log') || '{}')

  // Get default log level.
  singleton.setLevel(settings?.level || singleton.INFO);

  // Get the child loggers.
  (settings?.loggers || []).forEach((child) => {
    singleton.get(child.logger).setLevel(singleton[child.level])
  })
} catch (e) {
  console.error('Failed to parse log settings: ', e)
}
